<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
    <div class="ms-2">
      <div style="min-width: 180px" class="d-flex justify-content-between">
        <span
          v-tooltip
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('transactions_page.enable_data_stream')"
        >
          <button
            class="btn text-primary border"
            :class="{
              'bg-opacity-25': streaming,
              'bg-body': !streaming,
            }"
            :disabled="streaming"
            @click="startStream"
          >
            <i class="bi bi-play"></i>
          </button>
        </span>
        <span
          v-tooltip
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('transactions_page.pause_data_stream')"
        >
          <button
            class="btn text-primary border"
            :disabled="!streaming"
            :class="{
              'bg-opacity-25': !streaming,
              'bg-body': streaming,
            }"
            @click="pauseStream"
          >
            <i class="bi bi-pause"></i>
          </button>
        </span>
        <span
          v-tooltip
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('transactions_page.previous_page')"
        >
          <button
            class="btn text-primary border"
            :disabled="disablePrevBtn"
            @click="prevPage()"
          >
            <i class="bi bi-arrow-left"></i></button
        ></span>
        <span
          v-tooltip
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('transactions_page.next_page')"
        >
          <button
            class="btn text-primary border"
            :disabled="disableNextBtn"
            @click="nextPage()"
          >
            <i class="bi bi-arrow-right"></i></button
        ></span>
      </div>
    </div>
  </div>
  <txn-summary-skeleton v-if="loading" />
  <error v-else-if="error" />
  <txn-summary v-else />
</template>

<script>
import { defineComponent, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import Search from "@/components/Search.vue";
import TxnSummary from "@/components/summaries/TxnSummary.vue";
import Error from "@/components/Error.vue";
import TxnSummarySkeleton from "@/components/skeletons/summaries/TxnSummarySkeleton.vue";

export default defineComponent({
  name: "Transactions",
  components: { Search, TxnSummary, Error, TxnSummarySkeleton },
  setup() {
    const store = useStore();
    store.dispatch("transactions/startStreaming");
    const nextPage = () => {
      store.dispatch("transactions/fetchNextPage");
    };
    const prevPage = () => {
      store.dispatch("transactions/fetchPrevPage");
    };
    const startStream = () => {
      store.dispatch("transactions/startStreaming");
    };
    const pauseStream = () => {
      store.dispatch("transactions/stopStreaming");
    };
    const disablePrevBtn = computed(
      () => store.getters["transactions/disablePrevBtn"]
    );
    const disableNextBtn = computed(
      () => store.getters["transactions/disableNextBtn"]
    );
    const streaming = computed(() => store.getters["transactions/isStreaming"]);
    const loading = computed(
      () => store.getters["transactions/getLoadingStatus"]
    );
    const error = computed(() => store.getters["transactions/getError"]);
    onUnmounted(pauseStream);
    return {
      nextPage,
      prevPage,
      disablePrevBtn,
      disableNextBtn,
      startStream,
      pauseStream,
      streaming,
      loading,
      error,
    };
  },
});
</script>


    
